import { ICheckPriceResponse } from 'shared/repositories/dailyrent/v2/check-price';

import { ITypedReduxAction } from '../../types/redux';

export enum ECheckDailyrentPriceActionTypes {
  Loading = 'checkDailyrentPrice/loading',
  Success = 'checkDailyrentPrice/success',
  Fail = 'checkDailyrentPrice/fail',
}

export type TLoading = ITypedReduxAction<ECheckDailyrentPriceActionTypes.Loading, boolean>;
export type TSuccess = ITypedReduxAction<ECheckDailyrentPriceActionTypes.Success, ICheckPriceResponse>;
export type TFail = ITypedReduxAction<ECheckDailyrentPriceActionTypes.Fail, string>;

export type TCheckDailyrentPriceActions = TLoading | TSuccess | TFail;
