import { numberToPrettyString } from '@cian/utils/lib/shared/number_helpers';

import { ECheckDailyrentPriceActionTypes } from 'shared/actions/checkDailyrentPrice/types';
import { EDailyrentBookingCalendarActionTypes } from 'shared/actions/dailyrentBookingCalendar/types';
import { IDailyrentBookingGeneralState } from 'shared/store/dailyrentBookingParams/types';
import { IOfferData } from 'shared/types/offerData';
import { RootAction } from 'shared/types/redux/RootAction';
import { withNoBreakSpaces } from 'shared/utils/formatters';

const initialState: IDailyrentBookingGeneralState = {
  isLoading: false,
  price: '',
  paymentSummary: null,
  defaultBookingMessage: '',
  error: '',
};

export const dailyrentBookingGeneralReducer = (
  state: IDailyrentBookingGeneralState = initialState,
  action: RootAction,
): IDailyrentBookingGeneralState => {
  switch (action.type) {
    case 'IOfferFetched': {
      const {
        offer: { dailyrentAvailability, dailyrent },
      } = action.offerData as IOfferData;

      if (!dailyrentAvailability) {
        return state;
      }

      const paymentSummary = dailyrent?.paymentSummary || null;

      const { onlineBookingMessage = '', pricePerDayRur } = dailyrentAvailability;

      const price = pricePerDayRur ? withNoBreakSpaces(numberToPrettyString(pricePerDayRur)) + ' ₽/сут.' : '';

      return {
        ...state,
        defaultBookingMessage: onlineBookingMessage,
        price,
        paymentSummary,
      };
    }
    case ECheckDailyrentPriceActionTypes.Loading:
    case EDailyrentBookingCalendarActionTypes.SetAvailabilityLoading:
      return { ...state, isLoading: action.payload };
    case ECheckDailyrentPriceActionTypes.Success:
      return {
        ...state,
        defaultBookingMessage: action.payload.onlineBookingMessage || '',
        price: action.payload.mainPrice,
        paymentSummary: action.payload.paymentSummary || null,
        error: '',
      };
    case EDailyrentBookingCalendarActionTypes.SetError:
    case ECheckDailyrentPriceActionTypes.Fail:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};
