// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetOfferCardStatisticRequest,
  TGetOfferCardStatisticModel,
  IMappers,
  TGetOfferCardStatisticResponse,
  IGetOfferCardStatisticResponse,
  IGetOfferCardStatisticResponseError,
} from './types';

export const defaultConfig: TGetOfferCardStatisticModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'offer-card',
  pathApi: '/v1/get-offer-card-statistic/',
} as TGetOfferCardStatisticModel;

export function createGetOfferCardStatisticModel(
  parameters: IGetOfferCardStatisticRequest,
): TGetOfferCardStatisticModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetOfferCardStatisticOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetOfferCardStatisticRequest;
}

export async function fetchGetOfferCardStatistic<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetOfferCardStatisticOptions<TResponse200, TResponse400>): Promise<
  TGetOfferCardStatisticResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetOfferCardStatisticModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetOfferCardStatisticResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetOfferCardStatisticResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetOfferCardStatisticResponse;
}
