import { ECheckDailyrentPriceActionTypes } from 'shared/actions/checkDailyrentPrice/types';
import { EDailyrentBookingGuestsActionTypes } from 'shared/actions/dailyrentBookingGuests/types';
import { DEFAULT_VALUE, MAX_VALUE, MIN_VALUE } from 'shared/constants/guests';
import { IDailyrentBookingGuestsState } from 'shared/store/dailyrentBookingParams/types';
import { IOfferData } from 'shared/types/offerData';
import { RootAction } from 'shared/types/redux/RootAction';

const initialState: IDailyrentBookingGuestsState = {
  isOpen: false,
  guestsCount: DEFAULT_VALUE,
  minGuestsCount: MIN_VALUE,
  maxGuestsCount: MAX_VALUE,
};

export const dailyrentBookingGuestsReducer = (
  state: IDailyrentBookingGuestsState = initialState,
  action: RootAction,
): IDailyrentBookingGuestsState => {
  switch (action.type) {
    case 'IOfferFetched': {
      const {
        offer: { dailyrent },
      } = action.offerData as IOfferData;

      return {
        ...state,
        guestsCount: dailyrent?.guestsInfo?.guestsCount || DEFAULT_VALUE,
        maxGuestsCount: dailyrent?.guestsInfo?.maxGuestsCount || MAX_VALUE,
      };
    }
    case ECheckDailyrentPriceActionTypes.Success:
      return {
        ...state,
        guestsCount: action.payload.guestsCount || DEFAULT_VALUE,
        maxGuestsCount: action.payload.maxGuestsCount || MAX_VALUE,
      };
    case EDailyrentBookingGuestsActionTypes.ToggleGuestsModal:
      return {
        ...state,
        isOpen: typeof action.payload === 'boolean' ? action.payload : !state.isOpen,
      };
    case EDailyrentBookingGuestsActionTypes.SetGuestsCount:
      return {
        ...state,
        guestsCount: action.payload,
      };
    default:
      return state;
  }
};
