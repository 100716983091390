// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetRecommendedOffersRequest,
  TGetRecommendedOffersModel,
  IMappers,
  TGetRecommendedOffersResponse,
  IGetRecommendedOffersResponse,
  IGetRecommendedOffersResponseError,
} from './types';

export const defaultConfig: TGetRecommendedOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'search-engine',
  pathApi: '/v3/get-recommended-offers/',
  requestType: 'json',
} as TGetRecommendedOffersModel;

export function createGetRecommendedOffersModel(parameters: IGetRecommendedOffersRequest): TGetRecommendedOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRecommendedOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetRecommendedOffersRequest;
}

export async function fetchGetRecommendedOffers<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetRecommendedOffersOptions<TResponse200, TResponse400>): Promise<
  TGetRecommendedOffersResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetRecommendedOffersModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRecommendedOffersResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetRecommendedOffersResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetRecommendedOffersResponse;
}
