import { ECheckDailyrentPriceActionTypes } from 'shared/actions/checkDailyrentPrice/types';
import { EDailyrentBookingCalendarActionTypes } from 'shared/actions/dailyrentBookingCalendar/types';
import { IDailyrentBookingCalendarState } from 'shared/store/dailyrentBookingParams/types';
import { IOfferData } from 'shared/types/offerData';
import { RootAction } from 'shared/types/redux/RootAction';

const initialState: IDailyrentBookingCalendarState = {
  isOpen: false,
  canBeBooked: true,
  initialLoading: false,
  daysAvailability: [],
  showBookingCalendar: false,
  dateInputText: '',
  dates: {
    from: undefined,
    to: undefined,
  },
};

export const dailyrentBookingCalendarReducer = (
  state: IDailyrentBookingCalendarState = initialState,
  action: RootAction,
): IDailyrentBookingCalendarState => {
  switch (action.type) {
    case 'IOfferFetched': {
      const {
        offer: { dailyrentAvailability },
      } = action.offerData as IOfferData;

      if (!dailyrentAvailability) {
        return state;
      }

      const { showBookingCalendar, canBeBooked = false, dateInputText = '' } = dailyrentAvailability;

      return {
        ...state,
        showBookingCalendar,
        canBeBooked,
        dateInputText,
      };
    }

    case EDailyrentBookingCalendarActionTypes.SetDates:
      return { ...state, dates: action.payload };

    case EDailyrentBookingCalendarActionTypes.ToggleCalendarModal: {
      const open = typeof action.payload === 'boolean' ? action.payload : !state.isOpen;

      return { ...state, isOpen: open };
    }

    case EDailyrentBookingCalendarActionTypes.SetInitialLoading:
      return { ...state, initialLoading: action.payload };

    case EDailyrentBookingCalendarActionTypes.SetDaysAvailability:
      return { ...state, daysAvailability: action.payload };

    case ECheckDailyrentPriceActionTypes.Success:
      return {
        ...state,
        dateInputText: action.payload.dateInputText,
      };

    case ECheckDailyrentPriceActionTypes.Fail:
      return { ...state, canBeBooked: false };

    default:
      return state;
  }
};
