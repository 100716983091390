import { ValidationError } from '@cian/peperrors/shared';

import { requestHistoryBasedSimilarOffers } from './requestHistoryBasedSimilarOffers';
import { requestItemBasedSimilarOffers } from './requestItemBasedSimilarOffers';
import { requestNewItemBasedSimilarOffers } from './requestNewItemBasedSimilarOffers';
import { requestUserBasedSimilarOffers } from './requestUserBasedSimilarOffers';
import { TModelType } from '../types';

interface IRequestSimilarOffersParams {
  offerId: number;
  publishedUserId?: number;
  modelType: TModelType;
}

export function requestSimilarOffers(params: IRequestSimilarOffersParams) {
  const { offerId, publishedUserId, modelType } = params;

  switch (modelType) {
    case 'userBased':
      return requestUserBasedSimilarOffers(offerId, publishedUserId);

    case 'itemBased':
      return requestItemBasedSimilarOffers(offerId, publishedUserId);

    case 'newItemBased':
      return requestNewItemBasedSimilarOffers(offerId, publishedUserId);

    case 'historyBased':
      return requestHistoryBasedSimilarOffers();

    default:
      throw new ValidationError({
        message: `Передан некорректный тип модели похожих объявлений: ${modelType}`,
        domain: 'src/shared/store/similar/actions/requestSimilarOffers.ts',
      });
  }
}
