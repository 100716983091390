import { format } from 'date-fns';

import { getOfferStats } from 'shared/services/getOfferStats';
import { IOfferCardStatistics } from 'shared/services/getOfferStats/types';

import { IThunkActionCreator } from '../index';

export interface IOfferStatsPopupState {
  dataStats: IOfferCardStatistics | null;
  isOpen: boolean;
  statusFetch: EStatusFetch;
  messageError: string;
}

export enum EStatusFetch {
  none,
  isLoad,
  ok,
  error,
}

export interface IOfferStatsPopupStateOpen {
  type: 'IOfferStatsPopupStateOpen';
}

export interface IOfferStatsPopupStateClose {
  type: 'IOfferStatsPopupStateClose';
}

export interface IOfferStatsPopupStateFetching {
  payload: {
    messageError: string;
    statusFetch: EStatusFetch;
  };
  type: 'IOfferStatsPopupStateFetching';
}

export interface IOfferStatsPopupStateFetchingSuccess {
  payload: {
    data: IOfferCardStatistics;
    statusFetch: EStatusFetch;
  };
  type: 'IOfferStatsPopupStateFetchingSuccess';
}

export interface IOfferStatsPopupStateFetchingError {
  payload: {
    messageError: string;
    statusFetch: EStatusFetch;
  };
  type: 'IOfferStatsPopupStateFetchingError';
}

export function offerStatsPopupOpen(): IOfferStatsPopupStateOpen {
  return {
    type: 'IOfferStatsPopupStateOpen',
  };
}

export function offerStatsPopupClose(): IOfferStatsPopupStateClose {
  return {
    type: 'IOfferStatsPopupStateClose',
  };
}

export function submitOfferStats(id: number, creationDate: number): IThunkActionCreator {
  return (dispatch, getState, context) => {
    dispatch({
      payload: {
        messageError: '',
        statusFetch: EStatusFetch.isLoad,
      },
      type: 'IOfferStatsPopupStateFetching',
    });

    getOfferStats(context, {
      offerId: id,
      offerCreationDate: format(creationDate * 1000, 'yyyy-MM-dd'),
    }).then((response: IOfferCardStatistics) => {
      if (response === null) {
        dispatch({
          payload: {
            messageError: 'Не удалось загрузить данные. Попробуйте позже.',
            statusFetch: EStatusFetch.error,
          },
          type: 'IOfferStatsPopupStateFetchingError',
        });
      } else {
        dispatch({
          payload: {
            data: response,
            statusFetch: EStatusFetch.ok,
          },
          type: 'IOfferStatsPopupStateFetchingSuccess',
        });
      }
    });
  };
}

export type TOfferStatsPopupAction =
  | IOfferStatsPopupStateOpen
  | IOfferStatsPopupStateClose
  | IOfferStatsPopupStateFetching
  | IOfferStatsPopupStateFetchingSuccess
  | IOfferStatsPopupStateFetchingError;

const initialState = {
  dataStats: null,
  isOpen: false,
  messageError: '',
  statusFetch: EStatusFetch.none,
};

export function offerStatsPopupReducer(
  state: IOfferStatsPopupState = initialState,
  action: TOfferStatsPopupAction,
): IOfferStatsPopupState {
  switch (action.type) {
    case 'IOfferStatsPopupStateOpen':
      return {
        ...state,
        isOpen: true,
      };

    case 'IOfferStatsPopupStateClose':
      return {
        ...state,
        isOpen: false,
      };

    case 'IOfferStatsPopupStateFetching':
      return {
        ...state,
        messageError: action.payload.messageError,
        statusFetch: action.payload.statusFetch,
      };

    case 'IOfferStatsPopupStateFetchingSuccess':
      return {
        ...state,
        dataStats: action.payload.data,
        statusFetch: action.payload.statusFetch,
      };

    case 'IOfferStatsPopupStateFetchingError':
      return {
        ...state,
        messageError: action.payload.messageError,
        statusFetch: action.payload.statusFetch,
      };

    default:
      return state;
  }
}
