import { similarOffersReceived } from './similarOffersReceived';
import { getNewItemBasedSimilarOffers } from '../../../api/similar';
import { IThunkActionCreator } from '../../index';

export function requestNewItemBasedSimilarOffers(
  offerId: number,
  publishedUserId?: number,
): IThunkActionCreator<Promise<void>> {
  return (dispatch, getState, context) => {
    // обработки ошибок нет, и это нормально — деградируем без показа блока
    return getNewItemBasedSimilarOffers(context, { cianOfferId: offerId, publishedUserId }).then(res => {
      if (res.statusCode === 200) {
        dispatch(similarOffersReceived(res.response, 'newItemBased'));
      }
    });
  };
}
