// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TGetOffersFromHistoryModel,
  IMappers,
  TGetOffersFromHistoryResponse,
  IGetOffersFromHistoryResponse,
  IGetOffersFromHistoryResponseError,
} from './types';

export const defaultConfig: TGetOffersFromHistoryModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'GET',
  microserviceName: 'search-engine',
  pathApi: '/v1/get-offers-from-history/',
} as TGetOffersFromHistoryModel;

export function createGetOffersFromHistoryModel(): TGetOffersFromHistoryModel {
  return {
    ...defaultConfig,
  };
}

export interface IGetOffersFromHistoryOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

export async function fetchGetOffersFromHistory<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
}: IGetOffersFromHistoryOptions<TResponse200, TResponse400>): Promise<
  TGetOffersFromHistoryResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetOffersFromHistoryModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetOffersFromHistoryResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetOffersFromHistoryResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetOffersFromHistoryResponse;
}
