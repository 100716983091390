// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetNewRecommendedOffersRequest,
  TGetNewRecommendedOffersModel,
  IMappers,
  TGetNewRecommendedOffersResponse,
  IGetNewRecommendedOffersResponse,
  IGetNewRecommendedOffersResponseError,
} from './types';

export const defaultConfig: TGetNewRecommendedOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'search-engine',
  pathApi: '/v1/get-new-recommended-offers/',
  requestType: 'json',
} as TGetNewRecommendedOffersModel;

export function createGetNewRecommendedOffersModel(
  parameters: IGetNewRecommendedOffersRequest,
): TGetNewRecommendedOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewRecommendedOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetNewRecommendedOffersRequest;
}

export async function fetchGetNewRecommendedOffers<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetNewRecommendedOffersOptions<TResponse200, TResponse400>): Promise<
  TGetNewRecommendedOffersResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetNewRecommendedOffersModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewRecommendedOffersResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetNewRecommendedOffersResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetNewRecommendedOffersResponse;
}
