import { ITypedReduxAction } from '../../types/redux';

export enum EDailyrentBookingGuestsActionTypes {
  ToggleGuestsModal = 'dailyrentBookingGuests/toggleGuestsModal',
  SetGuestsCount = 'dailyrentBookingGuests/setGuestsCount',
}

export type TToggleGuestsModal = ITypedReduxAction<
  EDailyrentBookingGuestsActionTypes.ToggleGuestsModal,
  boolean | void
>;

export type TSetGuestsCount = ITypedReduxAction<EDailyrentBookingGuestsActionTypes.SetGuestsCount, number>;

export type TDailyrentBookingGuestsActions = TToggleGuestsModal | TSetGuestsCount;
