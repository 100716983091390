import { TDaysAvailability } from 'shared/types/bookingCalendar';

import { ITypedReduxAction } from '../../types/redux';

export enum EDailyrentBookingCalendarActionTypes {
  SetError = 'dailyrentBookingCalendar/setError',
  SetInitialLoading = 'dailyrentBookingCalendar/setInitialLoading',
  ToggleCalendarModal = 'dailyrentBookingCalendar/toggleCalendarModal',
  SetDates = 'dailyrentBookingCalendar/setDates',
  SetAvailabilityLoading = 'dailyrentBookingCalendar/setAvailabilityLoading',
  SetDaysAvailability = 'dailyrentBookingCalendar/setDaysAvailability',
}

export type TToggleCalendarModal = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.ToggleCalendarModal,
  boolean | void
>;
export type TSetError = ITypedReduxAction<EDailyrentBookingCalendarActionTypes.SetError, string>;
export type TSetInitialLoading = ITypedReduxAction<EDailyrentBookingCalendarActionTypes.SetInitialLoading, boolean>;
export type TSetAvailabilityLoading = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetAvailabilityLoading,
  boolean
>;
export type TSetDaysAvailability = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetDaysAvailability,
  TDaysAvailability
>;
export type TSetDates = ITypedReduxAction<
  EDailyrentBookingCalendarActionTypes.SetDates,
  { from: string | undefined; to: string | undefined }
>;

export type TDailyrentBookingCalendarActions =
  | TToggleCalendarModal
  | TSetDaysAvailability
  | TSetInitialLoading
  | TSetAvailabilityLoading
  | TSetError
  | TSetDates;
