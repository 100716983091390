import { ResponseError } from 'shared/errors/responseError';
import {
  fetchGetOfferCardStatistic,
  TGetOfferCardStatisticResponse,
} from 'shared/repositories/offer-card/v1/get-offer-card-statistic';
import { IApplicationContext } from 'shared/types/applicationContext';

import { IOfferCardStatistics } from './types';

interface IGetOfferSuggestionsParameters {
  offerCreationDate: string;
  offerId: number;
}

export async function getOfferStats(
  { httpApi, logger }: IApplicationContext,
  parameters: IGetOfferSuggestionsParameters,
): Promise<IOfferCardStatistics | null> {
  const { offerCreationDate, offerId } = parameters;
  try {
    const response: TGetOfferCardStatisticResponse = await fetchGetOfferCardStatistic({
      httpApi,
      parameters: {
        offerCreationDate,
        offerId,
      },
    });

    if (response.statusCode === 200) {
      return response.response;
    }

    logger.warning(
      new ResponseError({
        domain: 'getOfferStats',
        message: response.response.message?.toString() || 'Error while fetching offer stats',
        details: { errors: JSON.stringify(response.response.errors) },
      }),
    );

    return null;
  } catch (e) {
    logger.warning(e, {
      degradation: 'Cтатистика по объявлению',
      domain: 'getOfferStats',
    });

    return null;
  }
}
