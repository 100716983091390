// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IGetSimilarOffersRequest,
  TGetSimilarOffersModel,
  IMappers,
  TGetSimilarOffersResponse,
  IGetSimilarOffersResponse,
  IGetSimilarOffersResponseError,
} from './types';

export const defaultConfig: TGetSimilarOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'search-engine',
  pathApi: '/v3/get-similar-offers/',
  requestType: 'json',
} as TGetSimilarOffersModel;

export function createGetSimilarOffersModel(parameters: IGetSimilarOffersRequest): TGetSimilarOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetSimilarOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IGetSimilarOffersRequest;
}

export async function fetchGetSimilarOffers<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IGetSimilarOffersOptions<TResponse200, TResponse400>): Promise<
  TGetSimilarOffersResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createGetSimilarOffersModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetSimilarOffersResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IGetSimilarOffersResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TGetSimilarOffersResponse;
}
